import React, { useState, useEffect } from 'react'
import { Modal } from 'reactstrap';
import defaultPic from '../../assets/img/default-placeholder.png'
import { useFormik } from "formik";
import * as Yup from "yup";
import { getForm, postForm, putForm } from '../../api';
import { toast } from 'react-toastify';
import Loader from '../../shared-component/loader/Loader';

const AddTeamMember = ({ setIsModal, isModal, editModal, setEditModal, editId, getTeamListData }) => {
    const [Image, setImage] = useState('');
    const [ImageErr, setImageErr] = useState('');
    const [profilePic, setProfilePic] = useState('');
    const [imgSizeErr, setimgSizeErr] = useState('');
    const [viewData, setViewData] = useState('')
    const [IsLoader, setIsLoader] = useState(false)



    const handleClose = () => {
        setIsModal(false)
        setEditModal(false)
        setImageErr("")
        setImage("")
    }
    useEffect(() => {
        formik.resetForm();
        setProfilePic('')
    }, [isModal])
    useEffect(() => {
        getOurTeamViewData()
    }, [editModal])
    useEffect(() => {
        formik.setFieldValue('name', viewData && viewData.name ? viewData.name : '')
        formik.setFieldValue('designation', viewData && viewData.designation ? viewData.designation : '')
        setProfilePic(viewData && viewData.image ? viewData.image : '')

    }, [editModal && viewData])
    const formik = useFormik({
        initialValues: {
            image: "",
            name: "",
            designation: "",

        },
        validationSchema: Yup.object({
            name: Yup.string().trim()
                .required("*Name is required."),
            designation: Yup.string().trim()
                .required("*Designation is required."),

        }),
    });
    const uploadPhoto = (event) => {
        const data = event.target.value
        setImage(data)
        const file = event.target.files[0];
        const formData = new FormData();

        if (file) {
            let fileSize = file.size / 1e6;
            console.log("fileSize", fileSize);
            if (fileSize > 1) {
                setimgSizeErr("Please upload a file smaller than 1 MB");
            } else {
                formData.append("file", file);
                postForm("common/getUrl?file", formData).then((res) => {
                    setIsLoader(true)
                    if (res.status === 200) {
                        let url = res.data.result.url;
                        setProfilePic(url);
                        setIsLoader(false)
                        setImageErr("")
                    }
                });
            }
        }

    };
    const payload = {
        name: formik.values.name,
        image: profilePic,
        designation: formik.values.designation,


    };
    const hendleSubmit = () => {
        if (Image === '') {
            setImageErr("*Please add Image")
        }
        if (!formik.errors.name && !formik.errors.designation && profilePic !== '') {
            postForm('admin/team/addMember', payload, 'token').then((res) => {
                setIsLoader(true)
                if (res.status === 200) {
                    setIsModal(false)
                    toast("Team member added successfully" || "Success");
                    getTeamListData()
                    setIsLoader(false)
                    formik.resetForm()
                    setImageErr('')
                } else {
                    toast(res.data.responseMessage ,{ type: toast.TYPE.ERROR});
                    setIsLoader(false)
                }
            })
                .catch((err) => {
                    console.log("error", err);
                    toast(err.data.responseMessage ,{ type: toast.TYPE.ERROR});
                });
        }
    }

    const getOurTeamViewData = () => {
        getForm(`admin/team/viewMember/${editId}`, "token").then((res) => {
            if (res.status === 200) {
                const data = res.data.data;
                setViewData(data);
            }
        });
    };
    const hendleUpdate = () => {
        console.log(formik.errors);
        if (Image === '') {
            setImageErr("*Please add Image")
        }
        if (!formik.errors.name && !formik.errors.designation && Image !== '') {
            console.log('working');
            putForm(`admin/team/editMember?_id=${editId}`, payload, 'token').then((res) => {
                if (res.status === 200) {
                    setEditModal(false)
                    toast(res.data.responseMessage || "Success");
                    getTeamListData()
                    formik.resetForm()
                } else {
                    toast(res.data.responseMessage ,{ type: toast.TYPE.ERROR});
                }
            })
                .catch((err) => {
                    console.log("error", err);
                    toast(err.data.responseMessage ,{ type: toast.TYPE.ERROR});
                });
        }

    }


    return (
        <>
            <Modal isOpen={isModal || editModal} className="modal-dialog-centered testimonial-modal">
                {IsLoader && <Loader />}
                <div class="modal-header">
                    <h5 class="modal-title">
                        {editModal ? <>Edit</> : <>Add</>} Team Member</h5>
                    <button type="button" onClick={handleClose} class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-content p-2">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="cover_photo">
                                <figure>
                                    <img
                                        src={profilePic ? profilePic : defaultPic}
                                        className="img-fluid "
                                        alt="..."
                                    />
                                </figure>
                                <label class="position-camera" htmlFor='photoUpload'>
                                    <input id='photoUpload' type="file" name="image"
                                        hidden
                                        onChange={(e) => uploadPhoto(e)} />
                                    <i class="fa fa-camera "> </i></label>
                            </div>
                            <div>
                                {imgSizeErr && !profilePic ? <div className='error image-error'>{imgSizeErr}</div> : ''}
                                {!imgSizeErr && !profilePic ? <> {ImageErr ? <div className='error image-error'>{ImageErr}</div> : null}
                                </>
                                    : ''}
                            </div>
                            <div class="form-group mt-4">
                                <input type="text" class={`form-control ${formik.touched.name &&
                                    formik.errors.name ? 'is-invalid' : ''}`} name='name' maxLength="32" placeholder="Name" {...formik.getFieldProps("name")} />

                                {formik.touched.name &&
                                    formik.errors.name ? (
                                    <span className="error">
                                        {formik.errors.name}
                                    </span>
                                ) : null}
                            </div>
                            <div class="form-group">
                                <input type="text" class={`form-control ${formik.touched.designation &&
                                    formik.errors.designation ? 'is-invalid' : ''}`} name='designation' maxLength="32" placeholder="Designation"  {...formik.getFieldProps("designation")} />
                                {formik.touched.designation &&
                                    formik.errors.designation ? (
                                    <span className="error">
                                        {formik.errors.designation}
                                    </span>
                                ) : null}
                            </div>
                            <div className="action-butn text-center">
                                {editModal ? <button type='submit' onClick={hendleUpdate} className='btn btn-primary' >Update</button> :
                                    <button type='submit' onClick={hendleSubmit} className='btn btn-primary' >Submit</button>}
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default AddTeamMember
