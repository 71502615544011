/* eslint-disable linebreak-style */

import axios from "axios";
import qs from "querystring";
import { getSessionStorageOrDefault } from "../utils";

const baseURL = `${process.env.REACT_APP_GYM_DOCTOR_BASE_URL}`;

console.log("baseURL", baseURL);

export async function getForm(uri, authName) {
  try {
    const { data, status } = await axios.get(`${baseURL}` + uri, {
      headers: {
        token: getSessionStorageOrDefault(authName),
      },
    });

    return {
      data,
      status,
    };
  } catch (error) {
    if (error.response && error.response.data) {
      return {
        data: error.response.data,
        status: error.response.status,
      };
    }
    return {
      data: {},
      status: 404,
    };
  }
}

export async function postForm(uri, payload, authName) {
  try {
    const { data, status } = await axios.post(`${baseURL}` + uri, payload, {
      headers: {
        token: getSessionStorageOrDefault(authName),
      },
    });

    return {
      data,
      status,
    };
  } catch (error) {
    if (error.response && error.response.data) {
      return {
        data: error.response.data,
        status: error.response.status,
      };
    }
    return {
      data: {},
      status: 404,
    };
  }
}

export async function postEncoded({ uri, payload, authName }) {
  try {
    const { data, status } = await axios.post(baseURL + uri, qs.stringify(payload), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        token: getSessionStorageOrDefault(authName),
      },
    });
    return {
      data,
      status,
    };
  } catch (error) {
    if (error.response && error.response.data) {
      return {
        data: error.response.data,
        status: error.response.status,
      };
    }
    return {
      data: {},
      status: 404,
    };
  }
}

export async function putEncoded({ uri, payload, authName }) {
  try {
    const { data, status } = await axios.put(baseURL + uri, qs.stringify(payload), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        token: getSessionStorageOrDefault(authName),
      },
    });

    return {
      data,
      status,
    };
  } catch (error) {
    if (error.response && error.response.data) {
      return {
        data: error.response.data,
        status: error.response.status,
      };
    }
    return {
      data: {},
      status: 404,
    };
  }
}

export async function putForm(uri, payload, authName) {
  try {
    const { data, status } = await axios.put(`${baseURL}` + uri, payload, {
      headers: {
        token: getSessionStorageOrDefault(authName),
      },
    });

    return {
      data,
      status,
    };
  } catch (error) {
    if (error.response && error.response.data) {
      return {
        data: error.response.data,
        status: error.response.status,
      };
    }
    return {
      data: {},
      status: 404,
    };
  }
}

export async function axiosGet(uri, authName) {
  try {
    const { data, status } = await axios.get(`${baseURL}` + uri, {
      headers: {
        token: getSessionStorageOrDefault(authName),
      },
    });

    return {
      data,
      status,
    };
  } catch (error) {
    if (error.response && error.response.data) {
      return {
        data: error.response.data,
        status: error.response.status,
      };
    }
    return {
      data: {},
      status: 404,
    };
  }
}

export async function deleteForm(uri, authName) {
  try {
    const { data, status } = await axios.delete(`${baseURL}` + uri, {
      headers: {
        token: getSessionStorageOrDefault(authName),
      },
    });

    return {
      data,
      status,
    };
  } catch (error) {
    if (error.response && error.response.data) {
      return {
        data: error.response.data,
        status: error.response.status,
      };
    }
    return {
      data: {},
      status: 404,
    };
  }
}

export async function deleteEncoded({ uri, payload, authName }) {
  try {
    const { data, status } = await axios.delete(baseURL + uri, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        authName: getSessionStorageOrDefault(authName),
      },
      data: qs.stringify(payload),
    });

    return {
      data,
      status,
    };
  } catch (error) {
    if (error.response && error.response.data) {
      return {
        data: error.response.data,
        status: error.response.status,
      };
    }
    return {
      data: {},
      status: 404,
    };
  }
}

export async function patchForm(uri, authName) {
  try {
    console.log("ccc", baseURL, uri, authName, localStorage.getItem(authName));
    let token = getSessionStorageOrDefault(authName);
    const { data, status } = await axios.patch(
      `${baseURL}` + uri,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          token: token ? token : "",
        },
      }
    );

    return {
      data,
      status,
    };
  } catch (error) {
    if (error.response && error.response.data) {
      return {
        data: error.response.data,
        status: error.response.status,
      };
    }
    return {
      data: {},
      status: 404,
    };
  }
}
