import React,{useState,useEffect} from 'react';
import { Modal } from 'reactstrap';
import { getForm } from '../../api';
import defaultPic from '../../assets/img/default-placeholder.png'

const ViewModal = ({viewModal,setViewModal, viewId}) => {
    const [viewdata, setViewData] = useState('')

    const handleClose =()=>{
        setViewModal(false)
    }
    useEffect(() => {
        getTestimonialViewData()
    }, [viewId])

    const getTestimonialViewData = () => {
        getForm(`admin/testimonial/viewTestimonial/${viewId}`, "token").then((res) => {
          if (res.status === 200) {
            const data = res.data.data;
            setViewData(data);
          }
        });
      };
    return (
        <Modal isOpen={viewModal} className="modal-dialog-centered testimonial-modal view">
        <div class="modal-header">
            <h5 class="modal-title">
               View Testimonial</h5>
            <button type="button" onClick={handleClose} class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-content p-2">
                    <div className="cover_photo">
                        <figure>
                            <img
                                src={viewdata && viewdata.image ? viewdata.image : defaultPic}
                                className="img-fluid "
                                alt="..."
                            />
                        </figure>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Name</label>
                        <p>{viewdata && viewdata.name && viewdata.name}</p>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Designation</label>
                        <p>{viewdata && viewdata.designation && viewdata.designation}</p>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Description</label>
                        <p>{viewdata && viewdata.description && viewdata.description}</p>
                    </div>
            </div>
        </div>
    </Modal>
    )
}

export default ViewModal
