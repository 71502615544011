import React, { useState, useEffect } from "react";
import MainLogo from "../../assets/img/logo.png";
import SmallLogo from "../../assets/img/logo-small.png";
import SmallLogo2 from "../../assets/img/favicon.png";
import DocLogo from "../../assets/img/doctors/doctor-thumb-01.jpg";
import UserImg from "../../assets/img/profiles/avatar-01.jpg";
import { ReactComponent as NotiIc } from "../../assets/img/notification-fill.svg";
import "./style.css";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { axiosGet, postForm } from "../../api";
import { ADMIN_LAYOUT, AUTH_LAYOUT, CHANGE_PASSWORD, LOGIN, SITE_SETTINGS } from "../../config/RouterConfig";
import { useHistory } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import Sidebar from "../sidebar/Sidebar";
import { logOutManage } from "../../utils";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { USER_DATA } from "../../redux/action/ActionType";

function Header({ setNewToggle }) {
  let history = useHistory();
  const [Toggle, setToggle] = useState(true);
  const [ProfileData, setProfileData] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const dispatch = useDispatch();
  useEffect(() => {
    getmyProfile();
    console.log("APIII");
  }, []);

  const getmyProfile = () => {
    axiosGet("admin/account/myProfile", "token")
      .then((res) => {
        console.log("Profile res", res);
        if (res.status === 200) {
          setProfileData(res.data.userData);
          dispatch({ type : USER_DATA , payload : { profileData  : res.data.userData }})
        } else {
          localStorage.clear();
          history.push(`${AUTH_LAYOUT}${LOGIN}`);
        }
      })
      .catch((err) => {
        localStorage.clear();
        history.push(`${AUTH_LAYOUT}${LOGIN}`);
      });
  };

  const logOut = () => {
    postForm("admin/account/logout", "", "token").then((res) => {
      if (res.status === 200) {
        // localStorage.removeItem('token');
        logOutManage();
        window.location.replace("/");
        toast("You are successfully logged out" || "Success");
      }
    });
    logOutManage();
    window.location.replace("/");
  };

  const handleChngePwd = () => {
    history.push(`${ADMIN_LAYOUT}${CHANGE_PASSWORD}`);
  };
  const handleSiteSettings = () => {
    history.push(`${ADMIN_LAYOUT}${SITE_SETTINGS}`);
  };
  const handleToggle = () => {
    setToggle(!Toggle);
    setNewToggle(!Toggle);
  };
  useEffect(() => {
    if (Toggle) {
      document.body.classList.add("mini-sidebar");
    } else {
      document.body.classList.remove("mini-sidebar");
    }
    if (Toggle) {
      localStorage.setItem("isSideBar", "False");
    } else {
      localStorage.setItem("isSideBar", "True");
    }
  }, [Toggle]);

  return (
    <div class="header">
      <ToastContainer />
      <div class="header-left">
        <Link to="/" class="logo">
          <img src={MainLogo} alt="Logo" />
        </Link>
        <Link to="/" class="logo logo-small">
          <img src={SmallLogo2} alt="Logo" width="30" height="30" />
        </Link>
      </div>

      <a id="toggle_btn" onClick={handleToggle}>
        <i class="fe fe-text-align-left"></i>
      </a>

      {/* <div class="top-nav-search">
        <form>
          <input type="text" class="form-control" placeholder="Search here" />
          <button class="btn" type="submit">
            <i class="fa fa-search"></i>
          </button>
        </form>
      </div> */}

      {/* <!-- Mobile Menu Toggle --> */}
      <a class="mobile_btn" id="mobile_btn">
        <i class="fa fa-bars"></i>
      </a>
      {/* <!-- /Mobile Menu Toggle --> */}

      {/* <!-- Header Right Menu --> */}
      <ul class="nav user-menu">
        {/* <!-- Notifications --> */}
        <li class="nav-item dropdown noti-dropdown">
          {/* <a href="#" class="dropdown-toggle nav-link" data-toggle="dropdown">
            <i class="fe fe-bell"></i> <span class="badge badge-pill">3</span>
          </a> */}
          <div class="dropdown-menu notifications">
            <div class="topnav-dropdown-header">
              <span class="notification-title">Notifications</span>
              <a href="javascript:void(0)" class="clear-noti">
                {" "}
                Clear All{" "}
              </a>
            </div>
            <div class="noti-content">
              <ul class="notification-list">
                <li class="notification-message">
                  <a href="#">
                    <div class="media">
                      <span class="avatar avatar-sm">
                        <img class="avatar-img rounded-circle" alt="" src={DocLogo} />
                      </span>
                      <div class="media-body">
                        <p class="noti-details">
                          <span class="noti-title">Dr. Ruby Perrin</span> Schedule <span class="noti-title">her appointment</span>
                        </p>
                        <p class="noti-time">
                          <span class="notification-time">4 mins ago</span>
                        </p>
                      </div>
                    </div>
                  </a>
                </li>
                <li class="notification-message">
                  <a href="#">
                    <div class="media">
                      <span class="avatar avatar-sm">
                        <img class="avatar-img rounded-circle" alt="" src={DocLogo} />
                      </span>
                      <div class="media-body">
                        <p class="noti-details">
                          <span class="noti-title">Charlene Reed</span> has booked her appointment to <span class="noti-title">Dr. Ruby Perrin</span>
                        </p>
                        <p class="noti-time">
                          <span class="notification-time">6 mins ago</span>
                        </p>
                      </div>
                    </div>
                  </a>
                </li>
                <li class="notification-message">
                  <a href="#">
                    <div class="media">
                      <span class="avatar avatar-sm">
                        <img class="avatar-img rounded-circle" alt="" src="assets/img/patients/patient2.jpg" />
                      </span>
                      <div class="media-body">
                        <p class="noti-details">
                          <span class="noti-title">Travis Trimble</span> sent a amount of $210 for his <span class="noti-title">appointment</span>
                        </p>
                        <p class="noti-time">
                          <span class="notification-time">8 mins ago</span>
                        </p>
                      </div>
                    </div>
                  </a>
                </li>
                <li class="notification-message">
                  <a href="#">
                    <div class="media">
                      <span class="avatar avatar-sm">
                        <img class="avatar-img rounded-circle" alt="" src="assets/img/patients/patient3.jpg" />
                      </span>
                      <div class="media-body">
                        <p class="noti-details">
                          <span class="noti-title">Carl Kelly</span> send a message <span class="noti-title"> to his doctor</span>
                        </p>
                        <p class="noti-time">
                          <span class="notification-time">12 mins ago</span>
                        </p>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div class="topnav-dropdown-footer">
              <a href="#">View all Notifications</a>
            </div>
          </div>
        </li>
        {/* <!-- /Notifications --> */}
        {/* <div className="noti_box">
          <div className="noti_info">
            <NotiIc />
          </div>
        </div> */}

        {/* <!-- User Menu --> */}
        <li class="nav-item dropdown has-arrow">
          <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle caret>
              <span class="user-img">
                <img class="rounded-circle" src={ProfileData && ProfileData.profilePic ? ProfileData.profilePic : UserImg} width="31" alt="Ryan Taylor" />
              </span>
            </DropdownToggle>
            <DropdownMenu>
              <div class="user-header">
                <div class="avatar avatar-sm">
                  <img src={ProfileData && ProfileData.profilePic ? ProfileData.profilePic : UserImg} alt="" class="avatar-img rounded-circle" />
                </div>
                <div class="user-text">
                  <h6>{ProfileData && `${ProfileData.firstName} ${ProfileData.lastName}`}</h6>
                  <a class="text-muted mb-0" href="mailto:admin@gmail.com">
                    {ProfileData && ProfileData.email}
                  </a>
                </div>
              </div>
              <a class="dropdown-item" onClick={handleSiteSettings}>
                Site Settings
              </a>
              <a class="dropdown-item" onClick={handleChngePwd}>
                Change Password
              </a>
              <a class="dropdown-item" onClick={() => logOut()}>
                Logout
              </a>
            </DropdownMenu>
          </Dropdown>
        </li>

        {/* <li class="nav-item dropdown has-arrow">
          <a href="#" class="dropdown-toggle nav-link" data-toggle="dropdown">
            <span class="user-img">
              <img
                class="rounded-circle"
                src={UserImg}
                width="31"
                alt="Ryan Taylor"
              />
            </span>
          </a>
          <div class="dropdown-menu">
            <div class="user-header">
              <div class="avatar avatar-sm">
                <img
                  src="assets/img/profiles/avatar-01.jpg"
                  alt=""
                  class="avatar-img rounded-circle"
                />
              </div>
              <div class="user-text">
                <h6>Ryan Taylor</h6>
                <a class="text-muted mb-0" href="mailto:admin@gmail.com">
                  admin@gmail.com
                </a>
              </div>
            </div>

            <a class="dropdown-item" href="change-password.html">
              Change Password
            </a>
            <a class="dropdown-item" href="login.html">
              Logout
            </a>
          </div>
        </li> */}
        {/* <!-- /User Menu --> */}
      </ul>
      {/* <!-- /Header Right Menu --> */}
    </div>
  );
}

export default Header;
