export const AUTH_LAYOUT = "/auth";
export const ADMIN_LAYOUT = "/admin";
export const LOGIN = "/login";
export const FORGET_PASSWORD = "/forget-password";
export const VERIFY_OTP = "/verify-otp";
export const RESET_PASSWORD = "/reset-password";
export const CHANGE_PASSWORD = "/change-password";
export const DASHBOARD = "/dashboard";
export const SPECIALITIES = "/specialities";
export const SPECIALITIES_ADD_PRIMIRY = "/primary";
export const SPECIALITIES_EDIT_PRIMIRY = "/editprimary";
export const SPECIALITIES_ADD_SECONDARY = "/secondary";
export const SPECIALITIES_EDIT_SECONDARY = "/editsecondary";
export const ADD_SPECIALITIES = "/specialities/add";
export const PATIENTS = "/patients";
export const VIEW_PATIENTS = "/view-patient";
export const LIST_OF_SUBSCRIPTION = "/list-of-subscription";
export const ADD_SUBSCRIPTION = "/add-subscription";
export const EDIT_SUBSCRIPTION = "/edit-subscription/:subscriptionId";
export const VIEW_SUBSCRIPTION = "/view-subscription/:subscriptionId";
export const SUB_ADMIN = "/sub-admin";
export const ADD_SUBADMIN = "/add-sub-admin";
export const EDIT_SUBADMIN = "/edit-sub-admin";
export const VIEW_SUBADMIN = "/view-sub-admin";
export const APPOINTMENTS = "/appointments";
export const SERVICE_PROVIDERS = "/service-providers";
export const VIEW_SERVICE_PROVIDERS = "/view-service-providers";
export const REVIEWS = "/reviews";
export const VIEW_REVIEWS = "/view-reviews";
export const CONTENT_MANAGEMENT = "/content-management";
export const TERM_CONDITION = "/term-condition";
export const PRIVACY_POLICY = "/privacy-policy";
export const ABOUT_CMS = "/about";
export const LIST_OF_HELP = "/help";
export const VIEW_HELP = "/view-help";
export const REPLY_HELP = "/reply-help";
export const LIST_OF_COUPON = "/list-of-coupon";
export const TAX_MGT = "/tax-management";
export const ADD_COUPON = "/add-coupon";
export const VIEW_COUPON = "/view-coupon";
export const EDIT_COUPON = "/edit-coupon";
export const EARNING = "/earning";
export const REPORTS = "/reports";
export const ADD_REPORTS = "/add-reports";
export const EDIT_REPORTS = "/edit-reports";
export const VIEW_REPORTS = "/view-reports";
export const LABORATORY = "/laboratory";
export const ADD_LABORATORY = "/add";
export const EDIT_LABORATORY = "/edit";
export const LABORATORY_DETAIL = "/detail";
export const ADD_PACKAGES = "/add-packages";
export const EDIT_PACKAGES = "/edit-packages";
export const VIEW_PACKAGES = "/view-packages";
export const REFERRAL = "/referral";
export const REFERRAL_DETAILS = "/referral-details";
export const ADD_PATIENT_REPORTS = "/add-patient-reports";
export const EDIT_PATIENT_REPORTS = "/edit-patient-reports";
export const VIEW_PATIENT_REPORTS = "/view-patient-reports";
export const NOTIFICATIONS = "/notifications";
export const ADD_NOTIFICATIONS = "/add-notifications";
export const VIEW_NOTIFICATIONS = "/view-notifications";
export const SETTINGS = "/settings";
export const REVIEW_DETAIL_SERVICE_PROVIDER = "/view-service-provider";
export const REVIEW_DETAIL_LABORATORIES = "/view-laboratories";
export const SITE_SETTINGS = "/admin-site-settings";
export const VIEW_DOCTORS = "/view-doctor-details";
export const VIEW_INVOICE = "/view-invoice-details";
export const PAYMENT = "/payment";
export const PAYMENT_VIEW = "/view";

// cms

export const EDIT = "/edit";
export const VIEW = "/view";
export const HOW_IT_WORKS = "/how-it-works";
export const WHAT_WE_TREAT = "/what-we-treat";
export const METABOLIC_CENTERS = "/metabolic-centers";
export const PARTICIPATING_CENTER = "/participating-centers";
export const BECOME_A_PARTICIPATING_CENTER = "/become-a-participating-center";
export const REFER_A_CENTER = "/refer-a-center";
export const ONLINE_HEALTH_BLOG = "/online-healthblog";
export const OUR_MISSION = "/our-mission";
export const CONTACT_US = "/contact-us";
export const TESTIMONIALS = "/testimonials";
export const OUR_TEAM = "/our-team";
