import { getSessionStorageOrDefault } from "../../utils";
import { USER_DATA, LOGIN, LOGOUT } from "../action/ActionType";

const initialState = {
  profileData: {},
  token: getSessionStorageOrDefault("token")
    ? getSessionStorageOrDefault("token")
    : "",
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        token: action.payload.token,
      };
      case USER_DATA : return {
        ...state,
        profileData : action.payload.profileData
      }
    case LOGOUT:
      return initialState;
    default:
      return initialState;
  }
};

export default AuthReducer;
