import React, { useState, useEffect } from 'react';
import PaginationComponent from 'react-reactstrap-pagination';
import { Link } from 'react-router-dom';
import { getForm } from '../../api';
import { AUTH_LAYOUT, DASHBOARD } from '../../config/RouterConfig';
import ViewModal from '../testimonials/ViewModal';
import ContactViewModal from './ContactViewModal';


const ContactUs = () => {
    const [deleteModal, setDeleteModal] = useState(false)
    const [viewModal, setViewModal] = useState(false)
    const [contactusData, setcontactusData] = useState('')
    const [viewId, setViewId] = useState('')
    const [pageNumber, setPageNumber] = useState(1);
    const [limit, setLimit] = useState(5);
    const [search, setSearch] = useState("");


    const handleView = (id) => {
        setViewModal(true)
        setViewId(id)
    }

    useEffect(() => {
        getContactUsData()
    }, [limit, pageNumber, search])
    const getContactUsData = () => {
        getForm(`admin/contactUs/contactUs_list?pageNumber=${pageNumber}&limit=${limit}&search=${search}`, "token").then((res) => {
            if (res.status === 200) {
                const data = res.data;
                setcontactusData(data);
            }
        });
    };

    const handlePageLimit = (e) => {
        console.log("page data", e.target.value);
        setLimit(e.target.value);
    };
    const handleSearch = (e) => {
        let data = e.target.value;
        setSearch(data);
    };
    const handleSelected = (data) => {
        console.log("clicked page", data);
        setPageNumber(data);
    }
    console.log(contactusData);
    return (
        <div>
            <div class="page-header">
                <div class="row">
                    <div class="col-sm-7">
                        <h3 class="page-title">List of Contacts</h3>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item">
                                <Link to={`${AUTH_LAYOUT}${DASHBOARD}`}>Dashboard</Link>
                            </li>
                            <li class="breadcrumb-item active">Contacts</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="card contact-table">
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12 col-md-10">
                            <div className="dataTables_length" id="DataTables_Table_0_length">
                                <label>
                                    Show{" "}
                                    <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm" onChange={handlePageLimit}>
                                        <option value="5">5</option>
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                    </select>{" "}
                                    entries
                                </label>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-2">
                            <div id="DataTables_Table_0_filter" className="dataTables_filter">
                                <label>
                                    Search:
                                    <input type="search" className="form-control form-control-sm unique_search" placeholder="" aria-controls="DataTables_Table_0" onChange={(e) => handleSearch(e)} />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="datatable table table-hover table-center mb-0">
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Name</th>
                                    <th>Mobile Number</th>
                                    <th>Email</th>
                                    <th>Message</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {contactusData && contactusData.documentList && contactusData.documentList.length? (contactusData.documentList.map((item, i) =>
                                    <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>{item && item.name}</td>
                                        <td>{item && item.mobileNumber}</td>
                                        <td>{item && item.email}</td>
                                        <td><p className='twoLine'>{item && item.message}</p></td>
                                        <td class="text-center">
                                            <button className="btn btn-sm bg-primary-light mr-1" onClick={() => handleView(item._id)} title='View'>
                                                <i className="fe fe-eye"></i>
                                            </button>
                                        </td>
                                    </tr>
                                )) : <tr>
                                    <td colSpan="6">
                                        <p className='text-center'>Data not found!</p>
                                    </td>
                                    </tr>}
                            </tbody>
                        </table>
                    </div>
                    {contactusData && contactusData.documentList.length>4?
                    <div class="row">
                        <div class="col-sm-12 col-md-5">
                            <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
                                Showing {(pageNumber - 1) * limit + 1} to {(pageNumber - 1) * limit + contactusData.documentList?.length} of {contactusData && contactusData.totalList} entries
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-7">
                            <div class="dataTables_paginate paginationRight paging_simple_numbers" id="DataTables_Table_0_paginate">
                                <PaginationComponent
                                    totalItems={contactusData && contactusData && contactusData.totalList}
                                    pageSize={limit}
                                    maxPaginationNumbers={4}
                                    defaultActivePage={1}
                                    onSelect={(e) => handleSelected(e)}
                                />
                            </div>
                        </div>
                    </div> :''}
                </div>
            </div>

            <ContactViewModal
                viewModal={viewModal}
                setViewModal={setViewModal}
                viewId={viewId}
            />
        </div>
    )
}

export default ContactUs
