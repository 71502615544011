import React, { Suspense, lazy } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";

import {
  AUTH_LAYOUT,
  LOGIN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  ADMIN_LAYOUT,
  DASHBOARD,
  FORGET_PASSWORD,
  REGISTER,
  VERIFY_OTP,
} from "../config/RouterConfig";
import { getSessionStorageOrDefault } from "../utils";

const Login = lazy(() => import("../components/account/login/Login"));
const ForgetPassword = lazy(() =>
  import("../components/account/forgetPassword/ForgetPassword")
);
const VerifyOtp = lazy(() =>
  import("../components/account/verifyOtp/VerifyOtp")
);
const ResetPassword = lazy(() =>
  import("../components/account/resetPassword/ResetPassword")
);

const AuthLayout = () => {
  if (getSessionStorageOrDefault("token")) {
    return <Redirect to={`${ADMIN_LAYOUT}${DASHBOARD}`} />;
  }

  return (
    <Suspense fallback={"Loading..."}>
      <Switch>
        <Route exact path={`${AUTH_LAYOUT}${LOGIN}`} component={Login} />
        <Route
          exact
          path={`${AUTH_LAYOUT}${FORGET_PASSWORD}`}
          component={ForgetPassword}
        />
        <Route
          exact
          path={`${AUTH_LAYOUT}${VERIFY_OTP}`}
          component={VerifyOtp}
        />
        <Route
          exact
          path={`${AUTH_LAYOUT}${RESET_PASSWORD}`}
          component={ResetPassword}
        />
        <Redirect to={`${AUTH_LAYOUT}${LOGIN}`} />
      </Switch>
    </Suspense>
  );
};

export default AuthLayout;
