import React, { useState } from "react";
import { toast } from "react-toastify";
import { Modal, ModalBody } from "reactstrap";
import { patchForm } from "../../api";
import Loader from "../../shared-component/loader/Loader";
import './styles.css'

const DeleteModal = ({ deleteModal, setDeleteModal, deleteId, getTeamListData }) => {
  const [IsLoader, setIsLoader] = useState(false);
  const handleClose = () => {
    setDeleteModal(false);
  };

  const handleConfirm = () => {
    patchForm(`admin/team/actionPerform?_id=${deleteId}&status=DELETE`, "token").then((res) => {
      setIsLoader(true);
      console.log("actionresponse", res);
      if (res.status == 200) {
        setDeleteModal(false);
        getTeamListData();
        setIsLoader(false);
        toast("Deleted Successfully" || "Success");
      }
    });
  };
  return (
    <Modal isOpen={deleteModal} className="modal-dialog-centered delete_specialty_modal">
      {IsLoader && <Loader />}
      <ModalBody>
        <div class="modal-body">
          <div class="form-content p-2">
            <h4 class="modal-title">Delete</h4>
            <p class="mb-4">Are you sure want to delete?</p>
            <button type="button" class="btn btn-primary" onClick={handleConfirm}>
              Yes{" "}
            </button>
            <button type="button" onClick={handleClose} class="btn btn-danger" data-dismiss="modal">
              No
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DeleteModal;
