import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AUTH_LAYOUT, DASHBOARD, LOGIN, ADMIN_LAYOUT } from "../../config/RouterConfig";
import { getForm } from "../../api";
import AddTeamMember from "./AddTeamMember";
import DeleteModal from "./DeleteModal";
import Loader from "../../shared-component/loader/Loader";
import PaginationComponent from "react-reactstrap-pagination";
import "./styles.css";
import { axiosGet } from "../../api";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";

const OurTeam = () => {
  let history = useHistory();
  const [isModal, setIsModal] = useState(false);
  const [ourListData, setOurListData] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [editId, setEditId] = useState("");
  const [IsLoader, setIsLoader] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [limit, setLimit] = useState(5);
  const [search, setSearch] = useState("");

  const handleModal = () => {
    setIsModal(true);
  };
  useEffect(() => {
    if (limit) {
      getTeamListData();
    }
  }, [limit, pageNumber]);
  const getTeamListData = (searchData) => {
    let url = `admin/team/memberList`;
    // let urlOne= `admin/user/patientList`
    if (searchData) {
      let urlOne = `&search=${searchData}`;
      // url= url + urlOne
      if (pageNumber && limit) {
        let urlTwo = `?pageNumber=${pageNumber}&limit=${limit}`;
        url = url + urlTwo + urlOne;
      }
    } else {
      if (pageNumber && limit) {
        let urlTwo = `?pageNumber=${pageNumber}&limit=${limit}`;
        url = url + urlTwo;
      }
    }
    axiosGet(url, "token").then((res) => {
      setIsLoader(true);
      if (res?.status === 200) {
        const data = res?.data;
        setOurListData(data);
        setIsLoader(false);
        console.log(res);
      }
    });
  };

  const handleDelete = (id) => {
    setDeleteModal(true);
    setDeleteId(id);
  };
  const handleEdit = (id) => {
    setEditModal(true);
    setEditId(id);
  };
  const handlePageLimit = (e) => {
    console.log("page data", e.target.value);
    setLimit(e.target.value);
  };
  const handleSearch = (e) => {
    let data = e.target.value;
    getTeamListData(data);
  };
  const handleSelected = (data) => {
    setIsLoader(true);
    console.log("clicked page", data);
    setPageNumber(data);
    if (data) {
      // setselectedPage(data)
      axiosGet(`admin/team/memberList?pageNumber=${data}&limit=${limit}`, "token").then((res) => {
        setIsLoader(false);
        console.log("response>>>>>>>>", res);
        if (res.status === 200) {
          setOurListData(res.data);
          // setSpecialitiesList(res.data.documentList)
        } else if (res.status === 401) {
          history.push(`${AUTH_LAYOUT}${LOGIN}`);
        }
      });
    }
  };

  console.log("ourListData", ourListData);
  return (
    <div className="test_list">
      {IsLoader && <Loader />}
      <div class="page-header">
        <ToastContainer />
        <div class="row">
          <div class="col-sm-7">
            <h3 class="page-title">List of Team Members</h3>
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to={`${ADMIN_LAYOUT}${DASHBOARD}`}>Dashboard</Link>
              </li>
              <li class="breadcrumb-item active">Team Members</li>
            </ul>
          </div>
          <div class="col-sm-5 col">
            <button class="btn btn-primary float-right mt-2 mr-2" onClick={handleModal}>
              <i class="fa fa-plus-square mr-2"></i>Add Member
            </button>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-sm-12 col-md-10">
              <div className="dataTables_length" id="DataTables_Table_0_length">
                <label>
                  Show{" "}
                  <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm" onChange={(e) => handlePageLimit(e)}>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                  </select>{" "}
                  entries
                </label>
              </div>
            </div>
            <div className="col-sm-12 col-md-2">
              <div id="DataTables_Table_0_filter" className="dataTables_filter">
                <label>
                  Search:
                  <input type="search" className="form-control form-control-sm unique_search" placeholder="" aria-controls="DataTables_Table_0" onChange={(e) => handleSearch(e)} />
                </label>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="datatable table table-hover table-center mb-0">
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Designation</th>
                  <th class="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {ourListData?.documentList?.length ? (
                  ourListData?.documentList.map((item, i) => (
                    <tr key={i}>
                      <td>{(pageNumber - 1) * limit + i + 1}</td>
                      <td>
                        <figure>{item && item.image ? <img src={item && item.image} className="img-fluid" alt="" /> : ""}</figure>
                      </td>
                      <td>{item && item.name}</td>
                      <td>
                        <p className="twoLine">{item && item.designation}</p>
                      </td>

                      <td class="text-center">
                        <button className="btn btn-sm bg-success-light mr-1" onClick={() => handleEdit(item._id)} title="Edit">
                          <i className="fe fe-pencil"></i>
                        </button>
                        <button className="btn btn-sm bg-danger-light mr-1" onClick={() => handleDelete(item._id)} title="Delete">
                          <i className="fe fe-trash"></i>
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6">
                      <p className="text-center">Data not found!</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* {ourListData && ourListData.documentList.length > limit ? ( */}
          <div class="row">
            <div class="col-sm-12 col-md-5">
              <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
                Showing {(pageNumber - 1) * limit + 1} to {(pageNumber - 1) * limit + ourListData?.documentList?.length} of {ourListData?.totalList} entries
              </div>
            </div>
            {ourListData?.totalList > 8 ? <div class="col-sm-12 col-md-7">
              <div class="dataTables_paginate paginationRight paging_simple_numbers" id="DataTables_Table_0_paginate">
                <PaginationComponent totalItems={ourListData?.totalList} pageSize={limit} maxPaginationNumbers={4} defaultActivePage={1} onSelect={(e) => handleSelected(e)} />
              </div>
            </div>
              : ""}
          </div>
          {/* ) : (
            ""
          )} */}
        </div>
      </div>
      <AddTeamMember setIsModal={setIsModal} isModal={isModal} setEditModal={setEditModal} editModal={editModal} editId={editId} getTeamListData={getTeamListData} />
      <DeleteModal deleteModal={deleteModal} setDeleteModal={setDeleteModal} deleteId={deleteId} getTeamListData={getTeamListData} />
    </div>
  );
};

export default OurTeam;
