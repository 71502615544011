import React from "react";
import { Route, Redirect } from "react-router-dom";
import { AUTH_LAYOUT } from "../config/RouterConfig";
import { getSessionStorageOrDefault } from "../utils";

function PrivateRoute({ children, ...rest }) {
  let token = getSessionStorageOrDefault("token");
  token = true;
  return (
    <Route
      {...rest}
      render={({ location }) =>
        token ? (
          { children }
        ) : (
          <Redirect
            to={{
              pathname: `${AUTH_LAYOUT}`,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
