import React, { Suspense, lazy } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import LayoutContainer from "../layout-container/index";
import {
  ADD_SPECIALITIES,
  ADD_SUBADMIN,
  ADD_SUBSCRIPTION,
  ADMIN_LAYOUT,
  AUTH_LAYOUT,
  APPOINTMENTS,
  DASHBOARD,
  EDIT_SUBADMIN,
  EDIT_SUBSCRIPTION,
  LIST_OF_SUBSCRIPTION,
  LOGIN,
  PATIENTS,
  SPECIALITIES,
  SUB_ADMIN,
  VIEW_PATIENTS,
  VIEW_SUBADMIN,
  VIEW_SUBSCRIPTION,
  SERVICE_PROVIDERS,
  VIEW_SERVICE_PROVIDERS,
  REVIEWS,
  VIEW_REVIEWS,
  CONTENT_MANAGEMENT,
  EDIT_CONTENT_MANAGEMENT,
  TERM_CONDITION,
  PRIVACY_POLICY,
  VIEW_PRIVACY_POLICY,
  ABOUT_CMS,
  LIST_OF_HELP,
  VIEW_HELP,
  REPLY_HELP,
  LIST_OF_COUPON,
  VIEW_COUPON,
  EDIT_COUPON,
  EARNING,
  REPORTS,
  ADD_REPORTS,
  EDIT_REPORTS,
  VIEW_REPORTS,
  LABORATORY,
  ADD_COUPON,
  ADD_LABORATORY,
  EDIT_LABORATORY,
  LABORATORY_DETAIL,
  ADD_PACKAGES,
  REFERRAL_DETAILS,
  REFERRAL,
  NOTIFICATIONS,
  ADD_NOTIFICATIONS,
  VIEW_NOTIFICATIONS,
  SETTINGS,
  CHANGE_PASSWORD,
  SPECIALITIES_ADD_SECONDARY,
  SPECIALITIES_ADD_PRIMIRY,
  SPECIALITIES_EDIT_SECONDARY,
  SPECIALITIES_EDIT_PRIMIRY,
  REVIEW_DETAIL_SERVICE_PROVIDER,
  REVIEW_DETAIL_LABORATORIES,
  SITE_SETTINGS,
  EDIT_PACKAGES,
  VIEW_PACKAGES,
  HOW_IT_WORKS,
  REFER_A_CENTER,
  BECOME_A_PARTICIPATING_CENTER,
  PARTICIPATING_CENTER,
  EDIT,
  VIEW,
  WHAT_WE_TREAT,
  METABOLIC_CENTERS,
  OUR_TEAM,
  TESTIMONIALS,
  CONTACT_US,
  OUR_MISSION,
  ONLINE_HEALTH_BLOG,
  VIEW_DOCTORS,
  VIEW_INVOICE,
  PAYMENT,
  PAYMENT_VIEW,
  TAX_MGT,
} from "../config/RouterConfig";
import { getSessionStorageOrDefault } from "../utils";
import OurTeam from "../components/our-team/OurTeam";
import ContactUs from "../components/contact-us/ContactUs";

// import ChangePwd from '../components/account/changePassword/ChangePwd';

const Dashboard = lazy(() => import("../components/dashboard/Dashboard"));
const Specialities = lazy(() => import("../components/specialities"));
const AddSpecialities = lazy(() => import("../components/specialities/AddEditSpecialities"));
const ListOfPatients = lazy(() => import("../components/patient/index"));
const ViewPatient = lazy(() => import("../components/patient/ViewPatient"));
const ListOfSubscription = lazy(() => import("../components/subscription/index"));
const AddSubscription = lazy(() => import("../components/subscription/AddSubscription"));
const EditSubscription = lazy(() => import("../components/subscription/EditSubscription"));
const ViewSubscription = lazy(() => import("../components/subscription/ViewSubscription"));
const SubAdmin = lazy(() => import("../components/sub-admin/index"));
const AddSubadmin = lazy(() => import("../components/sub-admin/AddSubadmin"));
const EditSubadmin = lazy(() => import("../components/sub-admin/EditSubadmin"));
const ViewSubadmin = lazy(() => import("../components/sub-admin/ViewSubadmin"));
const Appointments = lazy(() => import("../components/appointments/index"));
const ServiceProviders = lazy(() => import("../components/service-providers/index"));
const ViewProviders = lazy(() => import("../components/service-providers/ViewProviders"));
const Reviews = lazy(() => import("../components/reviews/index"));
const ViewReviews = lazy(() => import("../components/reviews/ViewReviews"));
const ContentManagement = lazy(() => import("../components/content/index"));

const ListOfHelps = lazy(() => import("../components/help/index"));
const ViewHelp = lazy(() => import("../components/help/ViewHelp"));
const ReplyHelp = lazy(() => import("../components/help/ReplyHelp"));
const EditTax = lazy(() => import("../components/tax/EditTax"));
const ListOfCoupon = lazy(() => import("../components/coupon/index"));
const AddCoupon = lazy(() => import("../components/coupon/AddCoupon"));
const ViewCoupons = lazy(() => import("../components/coupon/ViewCoupons"));
const EditCoupons = lazy(() => import("../components/coupon/EditCoupons"));
const Earning = lazy(() => import("../components/earning/index"));
const Reports = lazy(() => import("../components/reports/index"));
const AddReports = lazy(() => import("../components/reports/AddReports"));
const EditReports = lazy(() => import("../components/reports/EditReports"));
const ViewReports = lazy(() => import("../components/reports/ViewReports"));
const Laboratory = lazy(() => import("../components/laboratories/Laboratory"));
const AddLaboratery = lazy(() => import("../components/laboratories/AddLaboratery"));
const EditLaboratery = lazy(() => import("../components/laboratories/EditLaboratery"));
const LaborateriesDetail = lazy(() => import("../components/laboratories/LaborateriesDetail"));
const AddPackages = lazy(() => import("../components/laboratories/add-packages/AddPackages"));
const Referral = lazy(() => import("../components/referral/Referral"));
const ReferralDetail = lazy(() => import("../components/referral/ReferralDetail"));
const Notification = lazy(() => import("../components/notification/index"));
const AddNotifications = lazy(() => import("../components/notification/AddNotifications"));
const ViewNotifications = lazy(() => import("../components/notification/ViewNotifications"));
const Settings = lazy(() => import("../components/settings/index"));
const changePwd = lazy(() => import("../components/account/changePassword/ChangePwd"));
const SecondarySpeciality = lazy(() => import("../components/specialities/SecondarySpeciality"));
const EditSecondarySpeciality = lazy(() => import("../components/specialities/EditSecondarySpeciality"));
const EditPrimiry = lazy(() => import("../components/specialities/EditPrimiry"));
const ViewServiceProvider = lazy(() => import("../components/reviews/serviceProviders/ViewServiceProvider"));
const ViewLaboratories = lazy(() => import("../components/reviews/laboratories/ViewLaboratories"));
const SiteSettings = lazy(() => import("../components/siteSettings/index"));

const EditPackage = lazy(() => import("../components/laboratories/Edit-packages/EditPackage"));

const ViewPackages = lazy(() => import("../components/laboratories/view-packages/ViewPackages"));
const CommonEdit = lazy(() => import("../components/content/common/CommonEdit"));
const CommonView = lazy(() => import("../components/content/common/CommonView"));
const Testimonials = lazy(() => import("../components/testimonials/Testimonials"));
const Newsletter = lazy(() => import("../components/contact-us/ContactUs"));
const ViewDoctordetails = lazy(() => import("../components/appointments/ViewDoctorDetails"));
const ViewInvoicedetails = lazy(() => import("../components/earning/ViewInvoice"));
const Payment = lazy(() => import("../components/payment/Payment"));
const PaymentView = lazy(() => import("../components/payment/PaymentView"));

function AdminLayout() {
  if (!getSessionStorageOrDefault("token")) {
    return <Redirect to={`${AUTH_LAYOUT}${LOGIN}`} />;
  }

  return (
    <>
      <LayoutContainer>
        <Suspense fallback={"Loading..."}>
          <Switch>
            <Route exact path={`${ADMIN_LAYOUT}${DASHBOARD}`} component={Dashboard} />
            <Route exact path={`${ADMIN_LAYOUT}${SPECIALITIES}${SPECIALITIES_ADD_PRIMIRY}`} component={Specialities} />
            <Route exact path={`${ADMIN_LAYOUT}${SPECIALITIES}${SPECIALITIES_EDIT_PRIMIRY}`} component={EditPrimiry} />
            <Route exact path={`${ADMIN_LAYOUT}${SPECIALITIES}${SPECIALITIES_ADD_SECONDARY}`} component={SecondarySpeciality} />
            <Route exact path={`${ADMIN_LAYOUT}${SPECIALITIES}${SPECIALITIES_EDIT_SECONDARY}`} component={EditSecondarySpeciality} />
            <Route exact path={`${ADMIN_LAYOUT}${ADD_SPECIALITIES}`} component={AddSpecialities} />
            <Route exact path={`${ADMIN_LAYOUT}${PATIENTS}`} component={ListOfPatients} />
            <Route exact path={`${ADMIN_LAYOUT}${VIEW_PATIENTS}/:PatientId`} component={ViewPatient} />
            <Route exact path={`${ADMIN_LAYOUT}${LIST_OF_SUBSCRIPTION}`} component={ListOfSubscription} />
            <Route exact path={`${ADMIN_LAYOUT}${ADD_SUBSCRIPTION}`} component={AddSubscription} />
            <Route exact path={`${ADMIN_LAYOUT}${EDIT_SUBSCRIPTION}`} component={EditSubscription} />
            <Route exact path={`${ADMIN_LAYOUT}${VIEW_SUBSCRIPTION}`} component={ViewSubscription} />
            <Route exact path={`${ADMIN_LAYOUT}${SUB_ADMIN}`} component={SubAdmin} />
            <Route exact path={`${ADMIN_LAYOUT}${ADD_SUBADMIN}`} component={AddSubadmin} />
            <Route exact path={`${ADMIN_LAYOUT}${EDIT_SUBADMIN}/:id`} component={EditSubadmin} />
            <Route exact path={`${ADMIN_LAYOUT}${VIEW_SUBADMIN}/:id`} component={ViewSubadmin} />

            <Route exact path={`${ADMIN_LAYOUT}${APPOINTMENTS}`} component={Appointments} />
            <Route exact path={`${ADMIN_LAYOUT}${SERVICE_PROVIDERS}`} component={ServiceProviders} />
            <Route exact path={`${ADMIN_LAYOUT}${VIEW_SERVICE_PROVIDERS}/:ServiceProviderId`} component={ViewProviders} />
            <Route exact path={`${ADMIN_LAYOUT}${REVIEWS}`} component={Reviews} />
            <Route exact path={`${ADMIN_LAYOUT}${VIEW_REVIEWS}`} component={ViewReviews} />
            <Route exact path={`${ADMIN_LAYOUT}${CONTENT_MANAGEMENT}`} component={ContentManagement} />
            <Route exact path={`${ADMIN_LAYOUT}${TERM_CONDITION}${EDIT}/:id`} component={CommonEdit} />
            <Route exact path={`${ADMIN_LAYOUT}${TERM_CONDITION}${VIEW}/:id`} component={CommonView} />
            <Route exact path={`${ADMIN_LAYOUT}${PRIVACY_POLICY}${EDIT}/:id`} component={CommonEdit} />
            <Route exact path={`${ADMIN_LAYOUT}${PRIVACY_POLICY}${VIEW}/:id`} component={CommonView} />
            <Route exact path={`${ADMIN_LAYOUT}${ABOUT_CMS}${EDIT}/:id`} component={CommonEdit} />
            <Route exact path={`${ADMIN_LAYOUT}${ABOUT_CMS}${VIEW}/:id`} component={CommonView} />
            <Route exact path={`${ADMIN_LAYOUT}${LIST_OF_HELP}`} component={ListOfHelps} />
            <Route exact path={`${ADMIN_LAYOUT}${VIEW_HELP}/:id`} component={ViewHelp} />
            <Route exact path={`${ADMIN_LAYOUT}${REPLY_HELP}/:id`} component={ReplyHelp} />
            <Route exact path={`${ADMIN_LAYOUT}${LIST_OF_COUPON}`} component={ListOfCoupon} />
            <Route exact path={`${ADMIN_LAYOUT}${TAX_MGT}`} component={EditTax} />
            <Route exact path={`${ADMIN_LAYOUT}${ADD_COUPON}`} component={AddCoupon} />
            <Route exact path={`${ADMIN_LAYOUT}${VIEW_COUPON}/:CouponId`} component={ViewCoupons} />
            <Route exact path={`${ADMIN_LAYOUT}${EDIT_COUPON}/:CouponId`} component={EditCoupons} />
            <Route exact path={`${ADMIN_LAYOUT}${EARNING}`} component={Earning} />
            <Route exact path={`${ADMIN_LAYOUT}${REPORTS}`} component={Reports} />
            <Route exact path={`${ADMIN_LAYOUT}${ADD_REPORTS}`} component={AddReports} />
            <Route exact path={`${ADMIN_LAYOUT}${EDIT_REPORTS}`} component={EditReports} />
            <Route exact path={`${ADMIN_LAYOUT}${VIEW_REPORTS}`} component={ViewReports} />
            <Route exact path={`${ADMIN_LAYOUT}${LABORATORY}`} component={Laboratory} />
            <Route exact path={`${ADMIN_LAYOUT}${LABORATORY}${ADD_LABORATORY}`} component={AddLaboratery} />
            <Route exact path={`${ADMIN_LAYOUT}${LABORATORY}${EDIT_LABORATORY}/:labId`} component={EditLaboratery} />
            <Route exact path={`${ADMIN_LAYOUT}${LABORATORY}${LABORATORY_DETAIL}/:LabId`} component={LaborateriesDetail} />
            <Route exact path={`${ADMIN_LAYOUT}${LABORATORY}${ADD_PACKAGES}/:LabId`} component={AddPackages} />
            <Route exact path={`${ADMIN_LAYOUT}${REFERRAL}`} component={Referral} />
            <Route exact path={`${ADMIN_LAYOUT}${REFERRAL_DETAILS}/:ReffralId`} component={ReferralDetail} />
            <Route exact path={`${ADMIN_LAYOUT}${NOTIFICATIONS}`} component={Notification} />
            <Route exact path={`${ADMIN_LAYOUT}${ADD_NOTIFICATIONS}`} component={AddNotifications} />
            <Route exact path={`${ADMIN_LAYOUT}${VIEW_NOTIFICATIONS}`} component={ViewNotifications} />
            <Route exact path={`${ADMIN_LAYOUT}${SETTINGS}`} component={Settings} />
            <Route exact path={`${ADMIN_LAYOUT}${CHANGE_PASSWORD}`} component={changePwd} />
            <Route exact path={`${ADMIN_LAYOUT}${REVIEW_DETAIL_SERVICE_PROVIDER}/:ReviewId`} component={ViewServiceProvider} />
            <Route exact path={`${ADMIN_LAYOUT}${REVIEW_DETAIL_LABORATORIES}`} component={ViewLaboratories} />
            <Route exact path={`${ADMIN_LAYOUT}${SITE_SETTINGS}`} component={SiteSettings} />
            <Route exact path={`${ADMIN_LAYOUT}${EDIT_PACKAGES}/:PackageId`} component={EditPackage} />
            <Route exact path={`${ADMIN_LAYOUT}${VIEW_PACKAGES}/:PackageId`} component={ViewPackages} />
            <Route exact path={`${ADMIN_LAYOUT}${HOW_IT_WORKS}${EDIT}/:id`} component={CommonEdit} />
            <Route exact path={`${ADMIN_LAYOUT}${HOW_IT_WORKS}${VIEW}/:id`} component={CommonView} />

            <Route exact path={`${ADMIN_LAYOUT}${WHAT_WE_TREAT}${EDIT}/:id`} component={CommonEdit} />
            <Route exact path={`${ADMIN_LAYOUT}${WHAT_WE_TREAT}${VIEW}/:id`} component={CommonView} />
            <Route exact path={`${ADMIN_LAYOUT}${METABOLIC_CENTERS}${EDIT}/:id`} component={CommonEdit} />
            <Route exact path={`${ADMIN_LAYOUT}${METABOLIC_CENTERS}${VIEW}/:id`} component={CommonView} />
            <Route exact path={`${ADMIN_LAYOUT}${PARTICIPATING_CENTER}${EDIT}/:id`} component={CommonEdit} />
            <Route exact path={`${ADMIN_LAYOUT}${PARTICIPATING_CENTER}${VIEW}/:id`} component={CommonView} />
            <Route exact path={`${ADMIN_LAYOUT}${BECOME_A_PARTICIPATING_CENTER}${EDIT}/:id`} component={CommonEdit} />
            <Route exact path={`${ADMIN_LAYOUT}${BECOME_A_PARTICIPATING_CENTER}${VIEW}/:id`} component={CommonView} />
            <Route exact path={`${ADMIN_LAYOUT}${REFER_A_CENTER}${EDIT}/:id`} component={CommonEdit} />
            <Route exact path={`${ADMIN_LAYOUT}${REFER_A_CENTER}${VIEW}/:id`} component={CommonView} />
            <Route exact path={`${ADMIN_LAYOUT}${ONLINE_HEALTH_BLOG}${EDIT}/:id`} component={CommonEdit} />
            <Route exact path={`${ADMIN_LAYOUT}${ONLINE_HEALTH_BLOG}${VIEW}/:id`} component={CommonView} />
            <Route exact path={`${ADMIN_LAYOUT}${OUR_MISSION}${EDIT}/:id`} component={CommonEdit} />
            <Route exact path={`${ADMIN_LAYOUT}${OUR_MISSION}${VIEW}/:id`} component={CommonView} />
            <Route exact path={`${ADMIN_LAYOUT}${CONTACT_US}${EDIT}/:id`} component={CommonEdit} />
            <Route exact path={`${ADMIN_LAYOUT}${CONTACT_US}${VIEW}/:id`} component={CommonView} />
            <Route exact path={`${ADMIN_LAYOUT}${TESTIMONIALS}`} component={Testimonials} />
            <Route exact path={`${ADMIN_LAYOUT}${OUR_TEAM}${EDIT}/:id`} component={CommonEdit} />
            <Route exact path={`${ADMIN_LAYOUT}${OUR_TEAM}${VIEW}/:id`} component={CommonView} />
            <Route exact path={`${ADMIN_LAYOUT}${CONTACT_US}`} component={ContactUs} />
            <Route exact path={`${ADMIN_LAYOUT}${OUR_TEAM}`} component={OurTeam} />
            <Route exact path={`${ADMIN_LAYOUT}${VIEW_DOCTORS}`} component={ViewDoctordetails} />
            <Route exact path={`${ADMIN_LAYOUT}${VIEW_INVOICE}`} component={ViewInvoicedetails} />
            <Route exact path={`${ADMIN_LAYOUT}${PAYMENT}`} component={Payment} />
            <Route exact path={`${ADMIN_LAYOUT}${PAYMENT}${PAYMENT_VIEW}/:id`} component={PaymentView} />
          </Switch>
        </Suspense>
      </LayoutContainer>
    </>
  );
}

export default AdminLayout;
