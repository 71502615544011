import React, { Component, lazy, Suspense } from 'react'
import { Route, Switch,  BrowserRouter as Router , Redirect } from 'react-router-dom';
import { createBrowserHistory } from "history";
import { ADMIN_LAYOUT, AUTH_LAYOUT } from '../config/RouterConfig';
import AuthLayout from '../layout-container/AuthLayout';
import AdminLayout from '../layout-container/AdminLayout';
import PrivateRoute from './PrivateRoute';


const Home =lazy(()=>import('../components/home/Home'));

export class RouterManagement extends Component {
    render() {
        const history= createBrowserHistory();
        return (
            <Router history={history}>
                <Suspense fallback={<div className="sl-loading">Loading...</div>}>
                <Switch>
                    <Route path={AUTH_LAYOUT} component={AuthLayout} />
                    <PrivateRoute path={ADMIN_LAYOUT} component={AdminLayout} />
                    <Redirect from="/" to={AUTH_LAYOUT} />
                </Switch>
                </Suspense>

             </Router>
        )
    }
}

export default RouterManagement

