import React,{useState} from 'react'
import { Container, Row, Col, CardBody } from 'reactstrap';
import RouterManagement from '../router-management';
import Footer from '../shared-component/footer/Footer';
import Header from '../shared-component/header/Header';
import Sidebar from '../shared-component/sidebar/Sidebar';

function LayoutContainer(props) {
    const [ToggleData, setToggleData] = useState(false)
    const setNewToggle=(data)=>{
       console.log("dataaaaaaaaaaaa",data);
       setToggleData(data)
    }
   
    return (
        <div>
            <div className="main-wrapper">
                <Header setNewToggle={setNewToggle}/>
                <div class="sidebar" id="sidebar">
                    <Sidebar ToggleData={ToggleData}/>
                </div>
                <div class="page-wrapper">
                    <div className="content container-fluid">
                        {props.children}
                    </div>
                    {/* <Footer /> */}
                </div>
            </div>
        </div>
    )
}

export default LayoutContainer
