import React, { useState, useEffect } from 'react'
import Scrollbars from 'react-custom-scrollbars';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom'
import { ADMIN_LAYOUT, APPOINTMENTS, CONTACT_US, CONTENT_MANAGEMENT, DASHBOARD, EARNING, LABORATORY, LIST_OF_COUPON, LIST_OF_HELP, LIST_OF_SUBSCRIPTION, NEWS_LETTER, NOTIFICATIONS, OUR_TEAM, PATIENTS, PAYMENT, REFERRAL, REPORTS, REVIEWS, SERVICE_PROVIDERS, SETTINGS, SPECIALITIES, SPECIALITIES_ADD_PRIMIRY, SPECIALITIES_ADD_SECONDARY, SUB_ADMIN, TAX_MGT, TESTIMONIALS } from '../../config/RouterConfig'

function Sidebar({ ToggleData }) {
    const { pathname } = useLocation();
    const [CheckedDash, setCheckedDash] = useState(false)
    let dashh = document.getElementById('dash')
    const [dropdown, setDropdown] = useState(false)
    const [LabDropdown, setLabDropdown] = useState(false);
    const adminProfile = useSelector(state=>state?.AuthReducer?.profileData);
    const permission = adminProfile?.permissions;
    const userType = adminProfile?.userType;
    console.log('admin permission', permission);
    console.log('adminProfile',adminProfile);
    console.log('userType',userType);

    useEffect(() => {
        if (pathname === `${ADMIN_LAYOUT}${SPECIALITIES}${SPECIALITIES_ADD_PRIMIRY}` || pathname === `${ADMIN_LAYOUT}${SPECIALITIES}${SPECIALITIES_ADD_SECONDARY}`) {
            setDropdown(true)
        }
        if (pathname !== `${ADMIN_LAYOUT}${SPECIALITIES}${SPECIALITIES_ADD_PRIMIRY}` && pathname !== `${ADMIN_LAYOUT}${SPECIALITIES}${SPECIALITIES_ADD_SECONDARY}`) {
            setDropdown(false)
        }
        if (pathname === `${ADMIN_LAYOUT}${LABORATORY}` || pathname === `${ADMIN_LAYOUT}${REPORTS}`) {
            setLabDropdown(true)
        }
        if (pathname !== `${ADMIN_LAYOUT}${LABORATORY}` && pathname !== `${ADMIN_LAYOUT}${REPORTS}`) {
            setLabDropdown(false)
        }
        if (ToggleData) {
            setDropdown(false)
            setLabDropdown(false)
        }
    }, [pathname, ToggleData])

    console.log("ToggleData", ToggleData);

    useEffect(() => {
        if (localStorage.getItem("isSideBar") === "False") {
            setDropdown(false)
            setLabDropdown(false)
        }
        console.log("localStorage", localStorage.getItem("isSideBar"));
    }, [localStorage.getItem("isSideBar")])

    console.log(pathname);
    const dropdownHandle = () => {
        setDropdown(!dropdown)
    }
    const dropdownLabHandle = () => {
        setLabDropdown(!LabDropdown)
    }
    const handleSideStyle = () => {
        document.body.classList.remove('mini-sidebar');
    }

    return (
        // <!-- Sidebar -->
        <div class="sidebar" id="sidebar">
            <div class="sidebar-inner slimscroll">
                <Scrollbars>
                    <div id="sidebar-menu" class="sidebar-menu">
                        <ul>
                            {console.log(userType)}
                          {(userType == 'ADMIN' ||permission?.dashboard) &&  <li className={pathname === "/admin/dashboard" ? "active" : ""}>
                                <Link to={`${ADMIN_LAYOUT}${DASHBOARD}`}><i class="fe fe-home"></i> <span id='dash'>Dashboard</span> </Link>
                            </li>}
                          {(userType === 'ADMIN' ||permission?.appointments) &&    <li className={pathname && pathname.includes('appointments') ? 'active' : ''}>
                                <Link to={`${ADMIN_LAYOUT}${APPOINTMENTS}`}><i class="fe fe-layout"></i> <span>Appointments</span></Link>
                            </li>}
                          {(userType === 'ADMIN' ||permission?.specialities) &&  <li className={pathname && pathname.includes('specialities') || dropdown ? 'dorpdown-dc active' : 'dorpdown-dc'} onClick={dropdownHandle}>
                                <a onClick={handleSideStyle}>   <i class="fe fe-users"></i> <span>Specialities</span> <span class="fe fe-arrow-right menu-arrow"></span></a>
                                {dropdown ?
                                    <ul>
                                        <li className={pathname === `${ADMIN_LAYOUT}${SPECIALITIES}${SPECIALITIES_ADD_PRIMIRY}` ? "active_option" : ""}><Link to={`${ADMIN_LAYOUT}${SPECIALITIES}${SPECIALITIES_ADD_PRIMIRY}`}>Primary Speciality</Link>  </li>
                                        <li className={pathname === `${ADMIN_LAYOUT}${SPECIALITIES}${SPECIALITIES_ADD_SECONDARY}` ? "active_option" : ""}><Link to={`${ADMIN_LAYOUT}${SPECIALITIES}${SPECIALITIES_ADD_SECONDARY}`}>Secondary Speciality</Link> </li>
                                    </ul>
                                    : ''}
                            </li>}
                           { (userType === 'ADMIN' ||permission?.serviceProviders )&&  <li className={pathname && pathname.includes('service-providers') ? 'active' : ''}>
                                <Link to={`${ADMIN_LAYOUT}${SERVICE_PROVIDERS}`}><i class="fe fe-user-plus"></i> <span>Service Providers</span></Link>
                            </li>}
                           { (userType === 'ADMIN' ||permission?.patients) &&  <li className={pathname && pathname.includes('patients') ? 'active' : ''}>
                                <Link to={`${ADMIN_LAYOUT}${PATIENTS}`}><i class="fe fe-user"></i> <span>Patients</span> </Link>
                            </li>}
                           {(userType === 'ADMIN' ||permission?.reviews) && <li className={pathname && pathname.includes('reviews') ? 'active' : ''}>
                                <Link to={`${ADMIN_LAYOUT}${REVIEWS}`}><i class="fe fe-star-o"></i> <span>Reviews</span></Link>
                            </li>}
                           {(userType === 'ADMIN' ||permission?.earning )&& <li className={pathname && pathname.includes('earning') ? 'active' : ''}>
                                <Link to={`${ADMIN_LAYOUT}${EARNING}`}><i class="fe fe-document"></i> <span> Earnings</span></Link>
                            </li>}
                          {(userType === 'ADMIN' ||permission?.withdrawRequest) &&   <li className={pathname && pathname.includes('payment')? 'active':''}>
                                <Link to={`${ADMIN_LAYOUT}${PAYMENT }`}><i class="fe fe-money icon-size-fa"></i> <span>Withdraw Request</span></Link>
                            </li>}
                            {/* <li className={pathname && pathname.includes('reports')? 'active':''}>
                            <Link to={`${ADMIN_LAYOUT}${REPORTS}`}><i class="fa fa-user-md icon-size-fa"></i> <span>Reports</span></Link>
                        </li> */}
                          {(userType === 'ADMIN' ||permission?.subscription) &&   <li className={pathname && pathname.includes('list-of-subscription') ? 'active' : ''}>
                                <Link to={`${ADMIN_LAYOUT}${LIST_OF_SUBSCRIPTION}`}><i class="fa fa-rocket icon-size-fa"></i>
                                    <span>Subscription</span> </Link>
                            </li>}
                           {(userType === 'ADMIN' ||permission?.laboratory) &&  <li className={pathname && pathname.includes('laboratory') || LabDropdown ? 'dorpdown-dc active' : 'dorpdown-dc'} onClick={dropdownLabHandle}>
                                <a onClick={handleSideStyle}>   <i class="fe fe-users"></i> <span>Laboratories</span>  <span class="fe fe-arrow-right menu-arrow"></span></a>
                                {LabDropdown ?
                                    <ul>
                                        <li className={pathname === `${ADMIN_LAYOUT}${LABORATORY}` ? "active_option" : ""}><Link to={`${ADMIN_LAYOUT}${LABORATORY}`}>Laboratories Management</Link></li>
                                        <li className={pathname === `${ADMIN_LAYOUT}${REPORTS}` ? "active_option" : ""}><Link to={`${ADMIN_LAYOUT}${REPORTS}`}>Reports</Link></li>
                                    </ul>
                                    : ''}
                            </li>}
                            {/* <li className={pathname && pathname.includes('laboratory')? 'active':''}>
                        <Link to={`${ADMIN_LAYOUT}${LABORATORY}`}><i class="fa fa-flask icon-size-fa"></i>
								<span>Laboratories Management</span></Link>
                        </li> */}
                           {(userType === 'ADMIN' ||permission?.subAdmin) && <li className={pathname && pathname.includes('sub-admin') ? 'active' : ''}>
                                <Link to={`${ADMIN_LAYOUT}${SUB_ADMIN}`}><i class="fa fa-tasks icon-size-fa"> </i>
                                    <span>Sub-Admin Management</span></Link>
                            </li>}
                           {(userType === 'ADMIN' ||permission?.content )&& <li className={pathname && pathname.includes('content-management') ? 'active' : ''}>
                                <Link to={`${ADMIN_LAYOUT}${CONTENT_MANAGEMENT}`}><i class="fe fe-document"></i> <span> Content Management</span></Link>
                            </li>}
                           {(userType === 'ADMIN' ||permission?.help) && <li className={pathname && pathname.includes('help') ? 'active' : ''}>
                                <Link to={`${ADMIN_LAYOUT}${LIST_OF_HELP}`}><i class="fa fa-question icon-size-fa"></i> <span>Help Management</span></Link>
                            </li>}
                            {(userType === 'ADMIN' )&& <li className={pathname && pathname.includes('tax-management') ? 'active' : ''}>
                                <Link to={`${ADMIN_LAYOUT}${TAX_MGT}`}><i class="fa fa-percent icon-size-fa"></i><span>Tax Management</span></Link>
                            </li>}
                           {(userType === 'ADMIN' ||permission?.coupon )&& <li className={pathname && pathname.includes('list-of-coupon') ? 'active' : ''}>
                                <Link to={`${ADMIN_LAYOUT}${LIST_OF_COUPON}`}><i class="fa fa-gift icon-size-fa"></i> <span>Coupon Management</span></Link>
                            </li>}
                           {(userType === 'ADMIN' ||permission?.referral )&& <li className={pathname && pathname.includes('referral') ? 'active' : ''}>
                                <Link to={`${ADMIN_LAYOUT}${REFERRAL}`}><i class="fe fe-activity"></i>
                                    <span>Referral Management</span></Link>
                            </li>}
                           {(userType === 'ADMIN' ||permission?.notification) &&  <li className={pathname && pathname.includes('notifications') ? 'active' : ''}>
                                <Link to={`${ADMIN_LAYOUT}${NOTIFICATIONS}`}><i class="far fa-bell"></i> <span>Notifications Managment</span></Link>
                            </li>}
                            {(userType === 'ADMIN' ||permission?.testimonial) &&  <li className={pathname && pathname.includes('testimonials') ? 'active' : ''}>
                                <Link to={`${ADMIN_LAYOUT}${TESTIMONIALS}`}><i class="fa fa-quote-left icon-size-fa"></i> <span>Testimonials</span></Link>
                            </li>}
                           {(userType === 'ADMIN' ||permission?.contactUs) && <li className={pathname && pathname.includes('news-letter') ? 'active' : ''}>
                                <Link to={`${ADMIN_LAYOUT}${CONTACT_US}`}><i class="fa fa-address-card icon-size-fa"></i><span>Contact Us</span></Link>
                            </li>}
                            {(userType === 'ADMIN' ||permission?.ourTeam) &&<li className={pathname && pathname.includes('Our-Team') ? 'active' : ''}>
                                <Link to={`${ADMIN_LAYOUT}${OUR_TEAM}`}><i class="fas fa-users icon-size-fa"></i><span>Our Team</span></Link>
                            </li>}
                           {(userType === 'ADMIN' ||permission?.setting) && <li className={pathname && pathname.includes('settings') ? 'active' : ''}>
                                <Link to={`${ADMIN_LAYOUT}${SETTINGS}`}><i class="fe fe-vector"></i> <span>Settings</span></Link>
                            </li>}
                        </ul>
                    </div>
                </Scrollbars>
            </div>
            {/* <Header setDropdown={setDropdown}/> */}
        </div>
        // <!-- /Sidebar -->
    )
}

export default Sidebar
